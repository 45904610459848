<template>
  <div :key="componentkey">
    <div id="app">
      <router-view />
    </div>
    <div id="popup-target" />
  </div>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import { mapActions, mapState } from 'vuex';
// import { AnalyticsHandler } from './components/common/Analytics/AnalyticsHandler';
import userApi, { logLogin } from './API/users-api';

export default {
  name: 'App',
  data() {
    return {
      signedIn: false,
      componentkey: 1,
      language: null,
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'themes']),
  },
  watch: {
    // // eslint-disable-next-line func-names
    // 'currentUser.user.language': function (val) {
    //   if (val !== this.language) {
    //     this.language = val;
    //     this.componentkey += 1;
    //   }
    // },
  },

  methods: {
    ...mapActions('users', ['setUserAssociatedCompanies', 'addCompanyDomain']),
    ...mapActions(['reset']),
    ...mapActions('users', [
      'setCurrentUser',
      'setCurrentTheme',
      'setFilteredThemes',
      'loadCurrentSubscription',
    ]),
    ...mapActions('users', ['setAssetFolder']),
    // ...mapActions('synonyms', ['loadSynonyms']),
    // ...mapActions('metrics', ['loadCurrentUserLimit']),
    // ...mapActions('categories', ['loadCategories']),
    // ...mapActions("learndata", ["updateLearnTables"]),
    isSigninAllowed(role) {
      // isSigninAllowed(authUser) {
      //   if(authUser.authenticationFlowType === "USER_SRP_AUTH") {
      //     //TODO: FOr SSO logged in users attributes are not set. Return true temporarily
      //     return true;
      //   }
      // const role =  authUser.attributes? authUser.attributes["custom:role"] : undefined;

      if (
        role &&
        (role === 'SuperAdmin' ||
          role === 'Admin' ||
          role === 'User' ||
          role === 'TrialUser')
      ) {
        return true;
      }
      return false;
    },
    async setCurrentUserInfo() {
      const userInfo = await this.getUserInfo();
      return this.setCurrentUser(userInfo);
    },
    getUserInfo() {
      return userApi.methods.getUserInfo();
    },
    navigateUser() {
      if (
        window.location.pathname.includes('/signin') ||
        window.location.pathname.includes('/redirect')
      ) {
        if (this.currentUser.user && this.currentUser.user.fingerPrint) {
          this.$router.push(
            sessionStorage.getItem('toLink') || '/home',
            () => {},
          );
          sessionStorage.removeItem('toLink');
        } else {
          this.$router.push(
            `/welcome?email=${this.currentUser.user.id}`,
            () => {},
          );
        }
        setTimeout(() => {
          this.navigateUser();
        }, 5000);
      }
    },
  },
  mounted() {
    if (this.currentUser?.user?.language) {
      this.$i18n.locale = this.currentUser.user.language || 'en';
    }
  },
  async beforeCreate() {
    Hub.listen('auth', (data) => {
      const {
        payload: { event },
      } = data;
      if (event === 'signIn') {
        logLogin();
        sessionStorage.setItem('isInitialSlideFilterOpened', false);
        window.localStorage.removeItem('vuex');
        this.signedIn = true;
        Auth.currentAuthenticatedUser().then(async (authUser) => {
          if (this.isSigninAllowed(authUser.attributes['custom:role'])) {
            this.reset();
            await this.setCurrentUserInfo();
            try {
              this.navigateUser();
            } catch (err) {
              console.log(err);
            }
          }
        });
      }
      if (event === 'signOut' || event === 'oAuthSignOut') {
        sessionStorage.removeItem('isInitialSlideFilterOpened');
        this.reset();
        this.$router.push('/signin', () => {});
        this.signedIn = false;
      }
    });
  },
};
</script>

<style lang="scss">
@import '@/scss/app.scss';
@import '@/scss/premium-features.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  margin-top: 0px;
  font-family: 'Lato', sans-serif;

  .v-tooltip__content {
    background: white !important;
    color: black !important;
    box-shadow: 1px -1px 4px 0 rgba(0, 0, 0, 0.15),
      -1px 1px 4px 2px rgba(0, 0, 0, 0.15) !important;
  }
  .v-btn--icon {
    color: rgba(0, 0, 0, 0.64);
  }
}
</style>
<style>
body * {
  font-family: 'Lato', sans-serif;
}
</style>
